import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCalendar, NgbDate, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CognitoService } from 'src/app/services/cognito.service';
import { ModalReadyComponent } from '../../shared/modal-ready/modal-ready.component';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { ProjectService, UploadService } from 'scantobim-openapi-frontend';
declare var jQuery:any
@Component({
  selector: 'app-upload-geoslam',
  templateUrl: './upload-geoslam.component.html',
  styleUrls: ['./upload-geoslam.component.css']
})
export class UploadGeoslamComponent implements OnInit {
  currentUser: any;
  orgName: any;
  faArrowLeft = faArrowLeft;
  faCalendar = faCalendarAlt
  faUpload = faUpload
  projectId: string = '';
  projectInfo: any;
  model!: NgbDateStruct 
  minDate!: NgbDateStruct 
  maxDate!: NgbDateStruct 
  today = inject(NgbCalendar).getToday();
  message: string = '';
  isSaving: boolean = false;
  fileProcessed: boolean = false;
  unsupportedFile: boolean = true;
  tag: string = '';
  fileName: string = '';
  selectedFile: any;
  fileId: string = 'file1'
  fileList: any
  unitId: string = "";
  projectUnits: any = []
  unitAreas: any;
  unitAreaDetails: any;
  areaId: any;
  scanDate: string = ''
  subscription: any = []
  successMessage: string = "";
  uploadDisabled: boolean = false;
  // projectStartDate: string = '';
  constructor(private route: ActivatedRoute,
    private authService: CognitoService,
    private projectService: ProjectService,
    private uploadService: UploadService,
    private router: Router,
    private _NgbModal: NgbModal) { }

  async ngOnInit() {
    const routeParams = this.route.snapshot.paramMap
    const orgName = routeParams.get('orgName');
    const projName = routeParams.get('projectId');

    this.orgName=orgName
    if(projName) {
      this.projectId = projName.toString()
    }
    if(this.projectId=='') {
      this.projectId = this.orgName.toLowerCase()+"-"+this.projectId.replace(/ /g,"_").toLowerCase()
    }
    this.currentUser = await this.authService.getCurrentUser()

    var sub1 = this.projectService.getProjectDetails(this.projectId,this.orgName.toLowerCase())
                                          .subscribe((result: any) => {
                                            if(!jQuery.isEmptyObject(result)) { 
                                              this.projectInfo = result[0]
                                              this.getFiles()
                                              this.model=this.today
                                              this.minDate = {
                                                "year":parseInt(this.projectInfo.startDate.substring(0,4)),
                                                "month":parseInt(this.projectInfo.startDate.substring(5,7)),
                                                "day": parseInt(this.projectInfo.startDate.substring(8,10)),
                                              };
                                              this.maxDate=this.today
                                              this.changeDate(this.model)
                                            } 
                                          })
  this.subscription.push(sub1)
                                          var sub2 = this.projectService.getUnitDetails(this.projectId,this.orgName.toLowerCase())
                                          .subscribe((result: any) => {
                                            if(!jQuery.isEmptyObject(result)) { 
                                              this.unitAreaDetails = result
                                              this.projectUnits = []
                                              for (var i=0; i<result.length; i++) {
                                                if (this.projectUnits.findIndex((x: any) => x.unitId === result[i].unitId)=== -1 && result[i].unitId !== '') {
                                                  var b = { unitId:result[i].unitId, unitName:result[i].unitName }
                                                  this.projectUnits.push(b);
                                                }
                                              }
                                              this.unitId=this.projectUnits[0].unitId
                                              this.selectUnit()
                                            } 
                                          })
    this.subscription.push(sub1)
    this.successMessage=""
    this.uploadDisabled = false
  }
  openModal() {
    const modalRef = this._NgbModal.open(ModalReadyComponent, { backdrop : 'static', keyboard : false});
    modalRef.result.then((result: any) => {
      if (result==1) {
        this.router.navigate(['/overview'])
      }
    })
  }
  
  changeDate(event: any) {
    if(this.model ) {
      this.message=''
      if(this.model.year!=undefined || this.model.month!=undefined || this.model.day!=undefined) {
        this.message = ''
        var day, month
      if(this.model.day<10) {
        day = "0"+this.model.day
      } else {
        day = this.model.day
      }
      if(this.model.month<10) {
        month = "0"+this.model.month
      } else {
        month = this.model.month
      }
      this.scanDate = this.model.year+"-"+month+"-"+day
      } else {
        this.message = 'Please enter valid Scan Date'
      }
    } else {
      this.message = "Please select Scan Date"
    }
  }

  async processFile(setupFiles: any){
    let file: File = setupFiles.files[0];
    this.selectedFile = undefined
    this.message = ''
    const reader = new FileReader();
    var length = 10;
    if(file.name) {
      var truncatedName = file.name.substring(0,Math.min(length,file.name.length));
      this.fileName = truncatedName+'...'
      var [filename, fileExtension] = file.name.split(/\.(?=[^\.]+$)/);
      fileExtension = fileExtension.toLowerCase()
      this.tag='geoslam'
      this.unsupportedFile = false
      var fileTypesAllowed = ["zip",]
      if(fileTypesAllowed.includes(fileExtension)==false)  {
        this.unsupportedFile = true
        this.displayMessage(`File formats allowed: .zip`, 'danger')
      }
      if(this.unsupportedFile == false) {
        this.isSaving = true
        this.fileId = filename.replace(/ /g,"_");
        reader.addEventListener('load', (event: any) => {
          this.selectedFile = {
            src: event.target.result,
            file: file
          }
          this.fileProcessed = true
          this.isSaving = false 
        })
        reader.readAsDataURL(file);
      } else {}
    }
  }

  displayMessage(message: string, messageType: string){
    this.message = message
  }
  onSave(){
    if(this.selectedFile ){
      this.isSaving = true
      this.fileProcessed = false   
      var sub3 = this.uploadService.addFilesForm(this.projectId, 
        this.fileId.replace(/ /g,"_"), 
        this.selectedFile.file, 
        this.tag, 
        this.currentUser.username, 
        this.unitId, 
        this.areaId,
        this.scanDate
        ).subscribe((v: any) => {
          if(v){
            this.isSaving = false
            this.unitId = ''
            this.areaId = ''
            this.getFiles()
            if(v.affectedRows>=1) {
              this.successMessage="File uploaded successfully"
              this.uploadDisabled = true
            }
          }
          }
        );
      this.subscription.push(sub3)
     } else if(!this.selectedFile || this.selectedFile!=undefined) {
      this.message = "No file uploaded, please retry."
     }
  }
  fileUnit(c: any) {
    this.unitId = c.value
    this.selectUnit()
  }
  selectUnit() {
    this.unitAreas = []
    for (var i=0; i<this.unitAreaDetails.length; i++) {
      if (this.unitAreaDetails[i].unitId===this.unitId) {
        var b = { areaId:this.unitAreaDetails[i].areaId, areaName:this.unitAreaDetails[i].areaName }
        this.unitAreas.push(b);
      }
    }
    this.areaId = this.unitAreas[0].areaId
  }
  fileArea(c: any) {
    this.areaId = c.value
  }
  getFiles() {
    var sub4 = this.uploadService.getFiles(this.projectId, this.currentUser.username).subscribe(result => {
      if(result && result.length>0) {
        for(var i=0; i<result.length;i++) {
          for(var j=0;j<this.unitAreaDetails.length;j++) {
            if(result[i].areaId===this.unitAreaDetails[j].areaId) {{
              result[i].unitId = this.unitAreaDetails[j].unitName
              result[i].areaId = this.unitAreaDetails[j].areaName
              break
            }}
          }
        }
        this.fileList  = result
      }
    })
    this.subscription.push(sub4)
  }
  ngOnDestroy() {
    if(this.subscription) {
      for(var i=0;i<this.subscription.length; i++) {
        this.subscription[i].unsubscribe()
      }
    }
  }
}
